import React, { useRef } from 'react'
import { Code, MessageCircle, Home, Twitter } from 'react-feather'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'
import { useTranslation } from 'react-i18next'

import { ExternalLink } from '../../theme'

const FooterFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  justify-content: center;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)
  const { t } = useTranslation()

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <FooterFrame ref={node as any}>
      <MenuItem id="link" href="https://cloudtx.finance/">
        <Home size={28} />
        <div>{t('Home')}</div>
      </MenuItem>
      <MenuItem id="link" href="https://twitter.com/CLOUDTX_">
        <Twitter size={28} />
        <div>{t('Twitter')}</div>
      </MenuItem>
      <MenuItem id="link" href="https://t.me/Cloudtx">
        <MessageCircle size={28} />
        <div>{t('Telegram')}</div>
      </MenuItem>
      <MenuItem id="link" href="https://github.com/cloudtxchain">
        <Code size={28} />
        <div>{t('Github')}</div>
      </MenuItem>
    </FooterFrame>
  )
}
